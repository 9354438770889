<style lang="sass" scoped>
.container-login
  // background-color: #f8f9fa
  font-size: 12px

  .title
    font-weight: bold
    font-size: 1.5rem
    color: #555

  .form-control
    font-size: 16px
.btn-google
  filter: brightness(1)
  transition: filter 200ms ease-out
  &:hover
    cursor: pointer
    filter: brightness(0.95)
    transition: filter 200ms ease-out
    // box-shadow

</style>
<template lang="pug">
div.container-login
  .container
    .row.justify-content-center.align-items-center(style='height: 100vh')
      .col-xl-5.col-lg-6.col-md-7
        h3.title.pb-3 로그인 중 ...

</template>

<script>

import qs from 'querystring'

export default {
  name: 'index',
  components: {

  },
  computed: {

  },
  mounted() {
    this.load()
    setTimeout(() => {
    }, 300);
  },
  data() {
    return {
      ENV: process.env,
    }
  },
  methods: {
    async load() {
      try {
        const hash = String(this.$route.hash || '').slice(1)
        if (hash.length) {
          const q = qs.parse(hash)

          const token = q.token
          const session = JSON.parse(q.session)

          window.localStorage.studio_token = token

          if (session.scope.length === 0) {
            // 로그인했지만 가입 절차 필요
            this.$router.push({
              name: 'signup.connect',
              query: {
                invitation: session.code,
              },
            })
            return
          }

          if (window.localStorage) {
            const property_subdomain = window.localStorage['noitaler.latest_property_subdomain']
            const uid = window.localStorage['noitaler.latest_user_id']
            if (property_subdomain && uid && +uid == +session.id) {
              return this.$router.push({
                name: 'layout',
                params: {
                  property_subdomain,
                }
              })
            }
          }

          this.$router.push({
            name: 'workspace.list'
          })
        }

      } catch (error) {
        console.log({error})
      }
    },
  },
}
</script>
